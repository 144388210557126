<template>
  <div>
    <router-view></router-view>
    <van-tabbar v-model="active" fixed border route inactive-color="#6F6F6F">
      <van-tabbar-item replace to="/used" :name="1">
        <span class="lable-text" :class="{ 'lable-color': active === 1 }">
          闲置专区
        </span>
        <template #icon="props">
          <img :src="props.active ? usedHighlight : usedImg" class="tab-img" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my-used" :name="2">
        <span class="lable-text" :class="{ 'lable-color': active === 2 }">
          我的闲置
        </span>
        <template #icon="props">
          <img :src="props.active ? myHighlight : myImg" class="tab-img" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/ascertain-order" :name="3">
        <span class="lable-text" :class="{ 'lable-color': active === 3 }">
          闲置订单
        </span>
        <template #icon="props">
          <img :src="props.active ? orderhlight : orderImg" class="tab-img" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "used",
  data() {
    return {
      active: 1,
      usedImg: require("@/assets/used/usedImg.png"),
      usedHighlight: require("@/assets/used/usedHighlight.png"),
      myImg: require("@/assets/used/myImg.png"),
      myHighlight: require("@/assets/used/myHighlight.png"),
      orderImg: require("@/assets/used/orderImg.png"),
      orderhlight: require("@/assets/used/orderhlight.png"),
    };
  },
  mounted() {
    let pathName = this.$router.history.current.name;
    switch (pathName) {
      case "used":
        this.active = 1;
        break;
      case "my-used":
        this.active = 2;
        break;
      default:
        this.active = 3;
        break;
    }
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.van-tabbar-item--active {
  color: #6f6f6f;
}
.van-tabbar {
  height: 63px;
}
.lable-text {
  font-size: 14px;
}
.lable-color {
  color: #ed301d;
}
.tab-img {
  display: block;
  width: 31px;
  height: 31px;
}
</style>